//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.react-datepicker-wrapper {
  display: block;
}

#splash-screen.hidden{
  position: fixed!important;
}


//===== Reach Checkbox Tree =====
.react-checkbox-tree ol {
  padding: 10px;
}

.react-checkbox-tree ol > li {
  padding: 5px;
}

.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-badge.kt-badge--username {
  padding: 0 40px;
}
